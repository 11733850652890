* {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}
html {
	font-size: 10px;
	box-sizing: border-box;
}

body {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:root {
	--toastify-color-success: #36d399 !important;
	--toastify-color-error: #f87272 !important;
	--toastify-color-dark: #0c0e12 !important;
}

#root {
	@import './imports';
	min-height: 100vh;
}
