p {
	font-size: calc(2rem + (0 * (100vw - 400px) / 624));
	line-height: calc(3.5rem + (0 * (100vw - 400px) / 624));
}
li {
	font-size: calc(2rem + (0 * (100vw - 400px) / 624));
	line-height: calc(3.5rem + (0 * (100vw - 400px) / 624));
}
h1 {
	font-size: calc(5rem + (0 * (100vw - 400px) / 624));
	line-height: calc(6rem + (0 * (100vw - 400px) / 624));
	font-weight: 800;
}
h2 {
	font-size: calc(4rem + (0 * (100vw - 400px) / 624));
	line-height: calc(5rem + (0 * (100vw - 400px) / 624));
	font-weight: 700;
}
h3 {
	font-size: calc(3.5rem + (0 * (100vw - 400px) / 624));
	line-height: calc(4.5rem + (0 * (100vw - 400px) / 624));
	font-weight: 600;
}
h4 {
	font-size: calc(3rem + (0 * (100vw - 400px) / 624));
	line-height: calc(3.5rem + (0 * (100vw - 400px) / 624));
	font-weight: 500;
}
h5 {
	font-size: calc(2.5rem + (0 * (100vw - 400px) / 624));
	line-height: calc(3.5rem + (0 * (100vw - 400px) / 624));
	font-weight: 500;
}
h6 {
	font-size: calc(3rem + (0 * (100vw - 400px) / 624));
}

.btn {
	font-size: calc(2rem + (0 * (100vw - 400px) / 624));
}

.card-title {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	inline-size: 35rem;
}

.card-snippet {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	inline-size: 35rem;
}
