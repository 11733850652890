.box-inherit {
	box-sizing: inherit;
}

.grid-colums-full {
	grid-template-columns: 100%;
}

.w-1000 {
	width: 1000px;
}

.h-1000 {
	height: 1000px;
}
